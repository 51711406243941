export var SearchList = [
  { labe: '日志描述', code: 'operation', type: 'input' },
  { labe: '接口', code: 'method', type: 'input' },
  { labe: '操作人', code: 'username', type: 'input' },
  {
    labe: '是否报错',
    code: 'isError',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '报错', value: '0' },
      { label: '正常', value: '1' }
    ]
  }
]
export var SearchData = {
  operation: '',
  method: '',
  username: '',
  isError: ''
}
export var tableField = [
  { label: '日志描述', code: 'operation', type: 'input', width: '' },
  { label: '接口', code: 'method', type: 'input', width: '' },
  { label: '传参', code: 'params', type: 'input', width: '' },
  { label: 'ip', code: 'ip', type: 'input', width: '' },
  { label: '操作人', code: 'username', type: 'input', width: '' },
  { label: '调用时长', code: 'time', type: 'input', width: '' },
  { label: '调用时间', code: 'createDate', type: 'input', width: '' },
  {
    label: '是否报错',
    code: 'isError',
    type: 'function',
    width: '',
    handle: (index, data) => {
      return data.isError === 1 ? '报错' : '正常'
    }
  },
  { label: '报错信息', code: 'errorMessage', type: 'input', width: '' },
  { label: '返回的数据', code: 'result', type: 'input', width: '' }
]
