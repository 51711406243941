<template>
  <div>
    <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
    <global-table
      :tableField="tableField"
      :tableData="tableData"
      :paginationData="paginationData"
      @pageChange="pageChange"
      @tableAction="tableAction"
    ></global-table>
  </div>
</template>

<script>
import { request } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField } from './js/setting.js'
export default {
  name: 'LogInfo',
  components: Component.components,
  data: function () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      childData: { searchList: SearchList, searchData: SearchData },
      tableField: tableField,
      tableData: [],
      paginationData: {
        pageSize: pageSize,
        total: 1
      },
      searchdata: {},
      pageSize: pageSize
    }
  },
  created: function () {
    request('/api/log/list', 'get', { pageNum: 1, pageSize: this.pageSize }).then((Response) => {
      if (Response.code === '200') {
        this.tableData = Response.data.records
        this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
      }
    })
  },
  methods: {
    pageChange: function (data) {
      this.searchdata.pageNum = data
      this.searchdata.pageSize = this.pageSize
      request('/api/log/list', 'get', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
        }
      })
    },
    search: function (data) {
      data.pageNum = 1
      data.pageSize = this.pageSize
      this.searchdata = data
      request('/api/log/list', 'get', data).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tablePadding.scss';
</style>
